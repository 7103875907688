import AppConfig from "../../../Config"

const { Config } = AppConfig
export const getEngagement = Config.Features.Dashboard
  ? `query GetEngagement($id: ID!) {
  getEngagement(id: $id) {
    Collector {
      UserAccessKey
      UserSecretKey
      UserAccessKeyStatus
      UserAccessKeyExpiryTime
      Certificate
      Installer
      Bootstrapper
      Status
    }
    Customer
    Id
    Name
    S3Bucket
    S3KeyPrefix
    Status
    DataShareConsentStatus
  }
}
`
  : `query GetEngagement($id: ID!) {
  getEngagement(id: $id) {
    Collector {
      UserAccessKey
      UserSecretKey
      UserAccessKeyStatus
      UserAccessKeyExpiryTime
      Certificate
      Installer
      Bootstrapper
      Status
    }
    Customer
    Id
    Name
    S3Bucket
    S3KeyPrefix
    Status
  }
}
`

export const getEngagementAndListReports = `query GetEngagementAndListReports($id: ID!) {
  getEngagementAndListReports(id: $id) {
    Collector {
      UserAccessKey
      UserSecretKey
      UserAccessKeyStatus
      UserAccessKeyExpiryTime
      Certificate
      Installer
      Bootstrapper
      Status
    }
    Customer
    Id
    Name
    S3Bucket
    S3KeyPrefix
    Status
    DataShareConsentStatus
    SummaryReports{
      Id
      DetailedId
      AvailableDetailedFormats
      CreatedOn
    }
  }
}
`

export const listEngagements = `query ListEngagements(
  $filter: EngagementFilterInput
  $limit: Int
  $nextToken: String
) {
  listEngagements(filter: $filter, limit: $limit, nextToken: $nextToken) {
    Engagements {
      Id
      Name
      Status
      Customer
      CollectorId
      DataShareConsentStatus
    }
    NextToken
  }
}
`

export const getEngagementSummaryReport = `query GetEngagementSummaryReport(
  $engagementId: ID!, $reportId: ID!
  ){
  getEngagementSummaryReport(engagementId: $engagementId, reportId: $reportId){
    Id
    EngagementName
    CompanyName
    CreatedOn
    AnalysisStartDate
    AnalysisEndDate
    HasEnoughUtilization
    ReportVersion
    Scenario{
      HasSoftwareAssurance
      PurchaseType
      Region
    }
    Data{
      CountByOperatingSystem {
        Windows
        RHEL
        SUSE
        Linux
        Other
      }
      CountByDatabase {
        SqlStandard
        SqlEnterprise
        SqlWeb
        SqlDeveloper
        SqlExpress
        Other
      }
      CountByServerType {
        VirtualMachines
        PhysicalServers
      }
      Utilization {
        PeakCpuUtilizationPercent
        PeakMemoryUtilizationPercent
      }
      StorageDetails {
        AttachedBlockStorage
      }
      DirectMatchResult {
        ComputeAnnualCost
        StorageAnnualCost
        Ec2Cost
        EbsCost
        TotalLicensesCost
        OSLicensesCost
        SoftwareLicensesCost
      }
      RightSizedMatchResult {
        ComputeAnnualCost
        StorageAnnualCost
        Ec2Cost
        EbsCost
        TotalLicensesCost
        OSLicensesCost
        SoftwareLicensesCost
      }
    }
  }
}`

export const getEngagementDetailedExport = `query getEngagementDetailedExport(
  $engagementId: ID!, $reportId: ID!, $format: String
) {
  getEngagementDetailedExport(engagementId: $engagementId, reportId: $reportId, format: $format) {
    ReportId
    EngagementId
    CreatedOn
    Data {
      S3SignedUrl
    }
  }
}
`
